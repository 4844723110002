/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Banner,
  MaterialIcon,
  SpinningIcon,
} from '@dsny/dsny-component-library';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import theme from 'src/styles/theme';
import { v4 as uuid } from 'uuid';
import { getSessionUser } from 'src/features/Auth';
import { uploadFileToS3 } from 'src/utils/Upload';
import * as Sentry from '@sentry/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { supportedFileFormats } from 'src/utils/RegexPatterns';
import { resetStateDashBoard } from 'src/features/Dashboard';
import { formatTextForSentryLog } from 'src/utils/Formatter';
import { getReleaseIdFromToken } from 'src/utils/Authenticate';
import { isCasterReleaseSong } from 'src/utils/VerifyTrackStatus';
import {
  MultiAssetBox,
  ContainerLeft,
  ContainerRight,
  UploaderProcessState,
  MultiAssetContainer,
  UploaderEmptyState,
} from './MultiAsset.styles';
import {
  PurchaseWrapper,
  FormBox,
  StepsWrapper,
  ClickableStep,
  ErrorWrapper,
  FormButton,
  MainFlexButtonWrapper,
} from '../Purchase.styles';
import { PurchaseCancelModal } from '../PurchaseCancelModal';
import AddSongFiles from './AddSongFiles/AddSongFiles';
import { AddTrackList, AddTrackObj, updateTracks } from './MultiAsset.slice';
import uploadFilesToPlaylist, { getReleaseTracks } from './MultiAsset.thunks';
import StepOneTitle from './StepOneTitle/StepOneTitle';
import StepOneTitleRelease from './StepOneTitle/StepOneTitleRelease';
import { ImportReleaseCancelModal } from './ImportRelease/ImportReleaseCancelModal';
import { ImportReleaseTrackedModal } from './ImportRelease/ImportReleaseTrackedModal';
import { ImportReleaseIssueModal } from './ImportRelease/ImportReleaseIssueModal';
import EditSongInfo from './EditSongInfo/EditSongInfo';
import MultiSongSelection from './MultiSongSelection/MultiSongSelection';

declare type bannerObj = {
  title: string;
  description: string;
};

const MultiAsset: React.FC = () => {
  const { t } = useTranslation();
  const {
    isFetching,
    hasError,
    trackIds,
    tracks: stateTracks,
    releaseTrack,
    hasUnauthorizedError,
  }: AddTrackList = useSelector((state: RootState) => state.multiAsset);
  const [showCancel, setShowCancel] = useState(false);
  const [showTrackedModal, setShowTrackedModal] = useState(false);
  const [showIssueModal, setShowIssueModal] = useState(false);
  const [tracks, setTracks] = useState<AddTrackObj[]>();
  const navigate = useNavigate();
  const [filesUploaded, setFilesUploaded] = useState(false);
  const [s3UploadError, sets3UploadError] = useState(false);
  const [updateStartDate, setUpdateStartDate] = useState(true);
  const [updateArtistName, setUpdateArtistName] = useState(true);
  const [errorMessage, setErrorMessage] = useState<bannerObj>();
  const dispatch = useDispatch<AppDispatch>();
  const { selectedSong } = useSelector(
    (state: RootState) => state.dashboard.songSelection
  );
  const [uploadedFileKeys, setUploadedFileKeys] = useState<Set<string>>(
    new Set<string>()
  );

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const casterToken = queryParams.get('token');

  const releaseId = queryParams.get('release');

  // File not supported
  const errorFileNotSupported = {
    title: t('ADD_TRACK_ERROR_FILE_NOT_SUPPORTED'),
    description: t('ADD_TRACK_ERROR_FILE_NOT_SUPPORTED_DESC'),
  };

  // Unable to upload file
  const errorUploadFailed = {
    title: t('MULTI_ASSET_ERROR_UPLOAD_FAILED'),
    description: t('MULTI_ASSET_ERROR_UPLOAD_FAILED_DESC'),
  };

  // No file uploaded
  const errorNoFileUploaded = {
    title: t('MULTI_ASSET_ERROR_NO_FILE_UPLOADED'),
    description: t('MULTI_ASSET_ERROR_NO_FILE_UPLOADED_DESC'),
  };

  // Duplicate file
  const duplicationErrorMsg = [
    'MULTI_ASSET_ERROR_DUPLICATE_SONG_DESC_ONE',
    'MULTI_ASSET_ERROR_DUPLICATE_SONG_DESC_TWO',
    'MULTI_ASSET_ERROR_DUPLICATE_SONG_DESC_THREE',
    'MULTI_ASSET_ERROR_DUPLICATE_SONG_DESC_MULTI',
  ];

  useEffect(() => {
    if (casterToken) {
      const relaseIdFromTOken = getReleaseIdFromToken(casterToken);
      dispatch(getReleaseTracks(relaseIdFromTOken));
    }
  }, [casterToken]);

  useEffect(() => {
    if (selectedSong) {
      dispatch(resetStateDashBoard());
    }
  }, [selectedSong]);

  useEffect(() => {
    if (releaseId) {
      dispatch(getReleaseTracks(releaseId));
    }
  }, [releaseId]);

  useEffect(() => {
    if (hasUnauthorizedError) {
      setShowIssueModal(true);
    }
  }, [hasUnauthorizedError]);

  useEffect(() => {
    if (releaseTrack.release_id && releaseTrack.isEmptyRelease) {
      setShowTrackedModal(true);
    }
  }, [releaseTrack]);

  useEffect(() => {
    if (releaseTrack.release_id && tracks?.length === 0) {
      navigate('/dashboard');
    }
  }, [releaseTrack.release_id, tracks]);

  useEffect(() => {
    if (!tracks && stateTracks.length > 0) {
      const newTracks = stateTracks.map((track) => ({ ...track }));
      setTracks(newTracks);
    }
  }, [stateTracks]);

  useEffect(() => {
    if (hasError) {
      setErrorMessage(errorUploadFailed);
    }
  }, [hasError]);

  useEffect(() => {
    const tempTracks = tracks;
    if (tempTracks && tempTracks.length === 1) {
      tempTracks[0].isSelected = '';
      setTracks(tempTracks);
    }
  }, [tracks?.length]);

  useEffect(() => {
    if (filesUploaded && trackIds.length > 0 && !isFetching && !hasError) {
      setFilesUploaded(false);
      dispatch(updateTracks({ tracks, trackIds }));
      navigate('/payment/selectpackage');
    }
  }, [trackIds, filesUploaded]);

  useEffect(() => {
    if (tracks && tracks.length) {
      const activeTracks = tracks.filter(
        (track) => track.isSelected || track.isActive
      );
      if (!activeTracks.length) {
        const newTracks = [...tracks];
        newTracks[0].isActive = true;
        setTracks(newTracks);
      }
    }
  }, [tracks]);

  // Form validation step one
  const { handleSubmit } = useForm({
    shouldUnregister: true,
  });

  // Handling active track
  const setActiveTrack = (newTracks: AddTrackObj[]) => {
    if (newTracks) {
      const activeTrack = newTracks.filter((track) => track.isActive);
      if (!activeTrack.length) {
        newTracks[0].isActive = true;
      }
    }
  };

  // Handling s3 file obj
  const createS3FileObj = (trackFile: File) => {
    const ASSETS_BUCKET_PATH = 'playlist';
    const user = getSessionUser();
    const fileId = uuid().replace(/-/gi, ''); // id is always unique
    const key = `${ASSETS_BUCKET_PATH}/${user.staffid}/${fileId}.dat`;

    return {
      bucket: process.env.REACT_APP_BUCKET!,
      key,
      filename: trackFile.name,
      fileId,
    };
  };

  // Handling banner error
  const fileHasError = (files: FileList) => {
    const filesArray: File[] = Array.from(files);

    let hasFileError = false;
    filesArray.forEach((file: File) => {
      if (!supportedFileFormats.exec(file.name)) {
        setErrorMessage(errorFileNotSupported);
        hasFileError = true;
      }
    });

    return hasFileError;
  };

  // Handling S3 timeout
  const setS3ErrorMessage = () => {
    setErrorMessage(errorUploadFailed);
    sets3UploadError(true);
  };

  // Handling file state
  const upDateFileState = (
    upLoadFile: AddTrackObj,
    trackList: AddTrackObj[],
    hasUploadError: boolean
  ) => {
    const uploadedTracks = [...trackList];

    uploadedTracks.map((track) => {
      if (track.file?.name === upLoadFile.file?.name) {
        track.isUpLoading = false;
        track.isFileUploadError = hasUploadError;
      }
      return track;
    });
    setTracks(uploadedTracks);
  };

  // Handling S3 error
  const handleS3Error = async (err: any, fileName: string, s3Key: string) => {
    if (err) {
      setS3ErrorMessage();
      Sentry.captureException(
        formatTextForSentryLog(
          `S3 file failed to upload ${err?.code} - ${err?.message} File: ${fileName} S3Key: ${s3Key}`
        )
      );
    }
  };

  // Handling duplicate file error message
  const getDuplicationErrorMessage = (index: number, fileNames: string[]) => {
    if (index - 1 < 3) {
      return {
        title: t('MULTI_ASSET_ERROR_DUPLICATE_SONG'),
        description: t(duplicationErrorMsg[index - 1], {
          file: fileNames[0],
          file2: fileNames[1] || '',
          file3: fileNames[2] || '',
        }),
      };
    }

    return {
      title: t('MULTI_ASSET_ERROR_DUPLICATE_SONG'),
      description: t(duplicationErrorMsg[3], {
        number: index,
      }),
    };
  };

  // Handling duplicate files
  const checkForFileDuplications = (uploadFiles: FileList) => {
    let duplicationsCount = 0;
    const fileNames: string[] = [];

    Array.from(uploadFiles).forEach((file) => {
      if (uploadedFileKeys.has(file.name)) {
        duplicationsCount += 1;
        fileNames.push(file.name);
      }
    });

    if (duplicationsCount > 0) {
      setErrorMessage(getDuplicationErrorMessage(duplicationsCount, fileNames));
    }
  };

  // Handling upload to S3
  const uploadToS3 = (
    upLoadFile: AddTrackObj,
    newTracks: AddTrackObj[]
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (upLoadFile?.file && upLoadFile?.S3File?.key) {
        uploadFileToS3(
          upLoadFile.file,
          upLoadFile?.S3File?.key,
          upLoadFile?.S3File?.fileId || '',
          (err) => {
            if (err) {
              handleS3Error(
                err,
                upLoadFile?.file?.name || '',
                upLoadFile?.S3File?.key
              );
              upDateFileState(upLoadFile, newTracks, true);
              reject(err);
            } else {
              upDateFileState(upLoadFile, newTracks, false);
              resolve();
            }
          }
        );
      } else {
        resolve();
      }
    });
  };

  // Handling onUpload track
  const onUpload = async (uploadFiles: FileList) => {
    setErrorMessage(undefined);
    sets3UploadError(false);
    checkForFileDuplications(uploadFiles);

    if (fileHasError(uploadFiles)) {
      return;
    }

    const tempFiles = Array.from(uploadFiles)
      .filter((file) => !uploadedFileKeys.has(file.name))
      .map((file) => {
        uploadedFileKeys.add(file.name);
        return {
          track_id: '',
          isFileUploaded: false,
          isFileUploadError: false,
          isFetching: false,
          file,
          artist: '',
          title: file.name,
          uploadS3Key: '',
          isSelected: '',
          isActive: false,
          S3File: createS3FileObj(file),
          isUpLoading: true,
          startTrackingDate: undefined,
        };
      });

    setUploadedFileKeys(uploadedFileKeys);

    if (tempFiles.length === 0) {
      return;
    }

    const newTracks: AddTrackObj[] = [...(tracks || []), ...tempFiles];
    setActiveTrack(newTracks);
    setTracks(newTracks);

    const uploadPromises = newTracks.map((track) => {
      return uploadToS3(track, newTracks).catch(() => {
        setS3ErrorMessage();
        return null;
      });
    });
    await Promise.all(uploadPromises);
  };

  // Handling delete track
  const handleTrackDelete = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number
  ) => {
    event.stopPropagation();
    if (tracks) {
      const newTracks = [...tracks];
      newTracks?.splice(index, 1);

      const { file } = tracks[index];
      if (file) {
        uploadedFileKeys.delete(file?.name);
        setUploadedFileKeys(uploadedFileKeys);
      }

      setTracks(newTracks);
      if (newTracks.length === 0 && s3UploadError) {
        sets3UploadError(false);
      }
    }
  };

  // Handling clear all artist fields
  const clearAllArtistFields = (tracksTemp: AddTrackObj[]) => {
    if (tracks) {
      tracksTemp
        .filter((track) => track.isSelected)
        .map((track) => {
          track.isFileUploadError = false;
          return track;
        });
      setTracks(tracksTemp);
    }
  };

  // Handling tracks selection
  const handleSelect = (event: React.ChangeEvent<Element>, index: number) => {
    event.stopPropagation();
    if (tracks) {
      const tracksTemp = [...tracks];
      tracksTemp[index].isSelected = tracksTemp[index].isSelected
        ? ''
        : 'itemCheckValue';

      tracksTemp[index].isActive = !tracksTemp.filter(
        (track) => track.isSelected
      ).length;
      if (tracksTemp[index].isSelected) {
        clearAllArtistFields(tracksTemp);
      } else {
        setTracks(tracksTemp);
      }
    }
  };

  // Handling tracks to batch
  const handleSelectAll = (isSelected: boolean) => {
    if (tracks) {
      const tracksTemp = [...tracks];
      tracksTemp.map((track) => {
        track.isSelected = isSelected ? '' : 'itemCheckValue';
        return track;
      });
      if (!isSelected) {
        clearAllArtistFields(tracksTemp);
      } else {
        setTracks(tracksTemp);
      }
    }
  };

  // Handling active selection
  const handleActiveSelection = (index: number) => {
    if (tracks) {
      const selecteditems = tracks.filter((track) => track.isSelected);

      if (selecteditems.length) {
        return;
      }

      const tracksTemp = [...tracks];
      tracksTemp.map((track, i) => {
        track.isActive = index === i;
        return track;
      });
      setTracks(tracksTemp);
    }
  };

  // Handling artist field
  const handleArtist = (artist: string) => {
    if (tracks) {
      const newTracks = [...tracks];
      newTracks
        .filter((track) => track.isSelected || track.isActive)
        .map((track) => {
          track.artist = artist;
          track.isFileUploadError = !track.title;
          return track;
        });
      setTracks(newTracks);
      setUpdateStartDate(false);
      setUpdateArtistName(true);
    }
  };

  // Handling title field
  const handleTitle = (title: string) => {
    if (tracks) {
      const newTracks = [...tracks];
      newTracks
        .filter((track) => track.isActive)
        .map((track) => {
          track.title = title;
          track.isFileUploadError = !track.artist;
          return track;
        });
      setTracks(newTracks);
    }
  };

  // Handling start tracking date field
  const handleStartDate = (startDate: Date) => {
    if (tracks) {
      const newTracks = [...tracks];
      newTracks
        .filter((track) => track.isSelected || track.isActive)
        .map((track) => {
          track.startTrackingDate = startDate;
          return track;
        });
      setTracks(newTracks);
      setUpdateStartDate(true);
      setUpdateArtistName(false);
    }
  };

  // Handling validate form
  const validateForms = () => {
    if (!tracks || !tracks.length) {
      setErrorMessage(errorNoFileUploaded);
      return false;
    }

    if (s3UploadError) {
      return false;
    }

    const newTracks = [...tracks];
    newTracks.map((track) => {
      track.isFileUploadError = !track.artist.trim() || !track.title.trim();
      return track;
    });
    setTracks(newTracks);

    if (newTracks.filter((track) => track.isFileUploadError).length)
      return false;

    return true;
  };

  // Handling tracks list
  const getTracksListForServerUpload = () => {
    return (
      tracks?.map((track) => {
        return {
          track_id: track.releaseTrack?.trackid || null,
          artist: track?.artist || track.releaseTrack?.artist,
          title: track?.title || track.releaseTrack?.title,
          upload_s3_bucket: track.S3File?.bucket,
          upload_s3_key: track.S3File?.key,
          src_filename: track.file?.name || '',
        };
      }) || []
    );
  };

  // Handling file submit
  const submitFile = async () => {
    if (validateForms()) {
      dispatch(
        uploadFilesToPlaylist({
          releaseId: releaseTrack?.release_id || '',
          identifier: uuid().replace(/-/gi, ''),
          tracks: getTracksListForServerUpload(),
        })
      );
      setFilesUploaded(true);
    }
  };

  if (isFetching) {
    return <></>;
  }

  return (
    <PurchaseWrapper>
      <MultiAssetContainer>
        <FormBox onSubmit={handleSubmit(submitFile)}>
          {/* Banner messages */}
          {errorMessage && (
            <ErrorWrapper>
              <Banner
                bannerContent={errorMessage}
                variant="error"
                width="782"
              />
            </ErrorWrapper>
          )}

          {/* Steps */}
          <StepsWrapper>
            <ClickableStep>{t('SIGNUP_STEP_ONE')}</ClickableStep>&nbsp;
            {t('SIGNUP_OF_3_STEP')}
          </StepsWrapper>

          {!releaseTrack.release_id ? (
            /* Title and subtitles */
            /* 1. Default upload flow */
            <StepOneTitle />
          ) : (
            /* 2. Caster release flow */
            <StepOneTitleRelease />
          )}

          {!tracks?.length ? (
            <>
              {/* Empty state - user first trigger the flow */}
              <UploaderEmptyState>
                <AddSongFiles onUpload={onUpload} />
              </UploaderEmptyState>
            </>
          ) : (
            <>
              {/* Processing state - user can add more songs */}
              <MultiAssetBox>
                <ContainerLeft>
                  {/* Multi Song Selection */}
                  <MultiSongSelection
                    tracks={tracks}
                    handleTrackDelete={handleTrackDelete}
                    handleSelect={handleSelect}
                    handleSelectAll={handleSelectAll}
                    handleActiveSelection={handleActiveSelection}
                    isCasterReleaseSong={isCasterReleaseSong}
                    isRelease={!!releaseTrack.release_id}
                  />
                  {/* Uploader */}
                  <UploaderProcessState>
                    <AddSongFiles onUpload={onUpload} />
                  </UploaderProcessState>
                </ContainerLeft>

                {/* Edit Song Info */}
                <ContainerRight>
                  <EditSongInfo
                    tracks={tracks}
                    handleTitle={handleTitle}
                    handleArtist={handleArtist}
                    handleStartDate={handleStartDate}
                    updateStartDate={updateStartDate}
                    updateArtistName={updateArtistName}
                    isCasterReleaseSong={isCasterReleaseSong}
                  />
                </ContainerRight>
              </MultiAssetBox>
            </>
          )}

          {/* Buttons */}
          <MainFlexButtonWrapper>
            <FormButton
              setting="mtr"
              variant="tertiary"
              fontSize="14px"
              type="button"
              onClick={() => setShowCancel(true)}
              style={{
                fontFamily: theme.fonts.primary,
                height: '36px',
                borderRadius: '5px',
              }}
            >
              {t('PURCHASE_CANCEL_BUTTON')}
            </FormButton>
            <FormButton
              setting="mtr"
              fontSize="14px"
              type="submit"
              disabled={
                !!tracks?.some((track) => track.isUpLoading === true) ||
                s3UploadError
              }
              style={{
                fontFamily: theme.fonts.primary,
                height: '36px',
                borderRadius: '5px',
                paddingTop: isFetching ? '14px' : '',
              }}
            >
              {isFetching ? (
                <SpinningIcon>
                  <MaterialIcon name="Rotate" />
                </SpinningIcon>
              ) : (
                t('PURCHASE_NEXT_BUTTON')
              )}
            </FormButton>
          </MainFlexButtonWrapper>
        </FormBox>

        {/* Modal - Purchase cancel */}
        {releaseTrack.release_id ? (
          /* 2. Caster release flow */
          <ImportReleaseCancelModal
            show={showCancel}
            setShowReleaseCancelModal={setShowCancel}
          />
        ) : (
          /* 1. Default flow */
          <PurchaseCancelModal
            show={showCancel}
            setShowCancel={setShowCancel}
          />
        )}

        {/* Modal - Caster release */}
        {releaseTrack.release_id && (
          /* All songs are already being tracked on MTR modal */
          <ImportReleaseTrackedModal
            show={showTrackedModal}
            setShowTrackedModal={setShowTrackedModal}
          />
        )}
        {/* User without permission modal */}
        <ImportReleaseIssueModal
          show={showIssueModal}
          setShowIssueModal={setShowIssueModal}
        />
      </MultiAssetContainer>
    </PurchaseWrapper>
  );
};

export default MultiAsset;
